import React, { useState, useContext, useEffect } from 'react';
import { useFetch } from 'use-http';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { MainContext } from '../../Providers/MainContext';
import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import {getLabel, profileTypeOptionsStringInserts} from '../../Util/constants';


export default function ConstructionPage() {
  const { jwt, profile } = useContext(MainContext);
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const { get, post, delete: deleter, response, loading, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  useEffect(() => {
    getConnections()
  }, [])

  const getConnections = async () => {
    const _data = await get('/api/connection')
    if (response.ok) {
      setData(_data)
    }
  }

  const rejectConnection = async (connection) => {
    await deleter(`/api/connection/${connection.id}`);
    if (response.ok) {
      getConnections();
    }
  }

  const acceptConnection = async (connection) => {
    await post('/api/connection/accept', { id: connection.id });
    if (response.ok) {
      navigate(`/profile/${connection.requester.id}`)
    }
  }

  const _renderPendingConnection = (connection) => {
    return (
      <Card sx={{ maxWidth: 400 }}>
        <CardHeader
          title={connection.acceptor.name}
          subheader={getLabel(connection.acceptor.profile_type, profileTypeOptionsStringInserts)}
        />
        <CardActions>
          <Button size="large" onClick={() => profile == connection.acceptor.id ? navigate(`/profile/${connection.requester.id}`) : navigate(`/profile/${connection.acceptor.id}`)}>View Profile</Button>
          <Button size="large" onClick={() => acceptConnection(connection)}>Accept</Button>
          <Button size="large" onClick={() => rejectConnection(connection)}>Reject</Button>
        </CardActions>
      </Card>
    )
  }

  const _renderPendingRequestConnection = (connection) => {
    console.log(connection.requester.id)
    return (
      <Card sx={{ maxWidth: 400 }}>
        <CardHeader
          title={connection.acceptor.name}
          subheader={getLabel(connection.acceptor.profile_type, profileTypeOptionsStringInserts)}
        />
        <CardActions>
          <Button size="large" onClick={() => profile == connection.acceptor.id ? navigate(`/profile/${connection.requester.id}`) : navigate(`/profile/${connection.acceptor.id}`)}>View Profile</Button>
          <Button size="large" onClick={() => rejectConnection(connection)}>Cancel</Button>
        </CardActions>
      </Card>
    )
  }


  const _renderConnection = (connection) => {
    const other_profile = connection.acceptor.id == profile ? connection.requester : connection.acceptor
    return (
      <Card sx={{ maxWidth: 400 }} key={connection.id}>
        <CardHeader
          title={other_profile.name}
          avatar={<Avatar  variant="raised" component="span" src={other_profile.profile_pic} />}
          subheader={getLabel(other_profile.profile_type, profileTypeOptionsStringInserts)}
        />
        <CardActions>
          <Button size="large" onClick={() => navigate(`/profile/${other_profile.id}`) }>View Profile</Button>
          <Button size="large" onClick={() => rejectConnection(connection)}>Remove Connection</Button>
        </CardActions>
      </Card>
    )
  }

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="md" sx={{ pt: 8, pb: 6 }}>
        <Typography component="h1">
          Connections
        </Typography>
        {error && 'Error!'}
        {loading && 'Loading...'}
        {data && <>
          <Typography component="h3">
            Pending Connections
          </Typography>
          {data.filter((connection) => !connection.accepted && connection.acceptor.id == profile).length == 0 && <Typography sx={{ ml: 3 }} component="h4">You have no Pending Connections</Typography>}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>{data.filter((connection) => !connection.accepted && connection.acceptor.id == profile).map((connection) => _renderPendingConnection(connection))}</div>
          <Typography component="h3">
            Pending Requests
          </Typography>
          {data.filter((connection) => !connection.accepted && connection.requester.id == profile).length == 0 && <Typography sx={{ ml: 3 }} component="h4">You have no Pending Requests</Typography>}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>{data.filter((connection) => !connection.accepted && connection.requester.id == profile).map((connection) => _renderPendingRequestConnection(connection))}</div>
          <Typography component="h3">
            Connections
          </Typography>
          {data.filter((connection) => connection.accepted).length == 0 && <Typography sx={{ ml: 3 }} component="h4">You have no Connections</Typography>}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>{data.filter((connection) => connection.accepted).map((connection) => _renderConnection(connection))}</div>
        </>
        }
      </Container>
    </Container>
  );
}